<route>
{
  "meta": {
    "permission": [
      "matrix.view_matrixtemplate"
    ]
  }
}
</route>

<template>
  <i-container :opts="opts" :mode="container" @input="$emit('input', $event)">
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar
            :title="
              container == 'modal'
                ? $t(matrix.name)
                : edit
                ? $t('see_matrix')
                : $t('create_matrix')
            "
            :loading="loading"
          ></i-toolbar>
          <v-card-text>
            <!-- Nombre y tipo de matriz -->
            <v-row class="my-0 py-3">
              <v-col class="pt-0" cols="12" sm="9" md="4">
                <ValidationProvider
                  vid="name"
                  :name="$tc('name', 1)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    maxlength="50"
                    :error-messages="errors[0]"
                    :label="$tc('name', 1)"
                    v-model="matrix.name"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col class="pt-0" cols="12" sm="3" md="2">
                <v-switch
                  class="v-switch mt-0"
                  disabled
                  hide-details
                  :label="$t('one_row')"
                  v-model="one_row"
                ></v-switch>
              </v-col>
            </v-row>

            <!-- Tabs -->
            <v-row class="my-0">
              <v-col cols="9" md="10" xl="11">
                <v-tabs class="matrix-tabs" fixed-tabs>
                  <v-tab
                    v-for="(item, key) in tabs"
                    :key="key"
                    @change="tabValue(item.value)"
                  >
                    {{ item.value }}
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="3" md="2" xl="1">
                <div class="tabsButtonColum">
                  <v-btn
                    :small="$vuetify.breakpoint.mdAndUp"
                    :x-small="$vuetify.breakpoint.smAndDown"
                    color="error"
                    @click="RemoveValue"
                    :class="$vuetify.breakpoint.smAndUp ? 'mr-1' : ''"
                  >
                    <v-icon small class>fa-minus</v-icon>
                  </v-btn>
                  <v-btn
                    :small="$vuetify.breakpoint.mdAndUp"
                    :x-small="$vuetify.breakpoint.smAndDown"
                    color="success"
                    @click="openDialogTabs()"
                  >
                    <v-icon small class>fa-plus</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <!-- Matrix -->
            <v-row class="my-0">
              <v-col cols="12" class="matrix-table-container pb-3">
                <table class="matrix-table">
                  <thead>
                    <tr>
                      <!-- Esquina -->
                      <th class="d-flex flex-row g-1 matrix-corner pt-3">
                        <ValidationProvider
                          vid="item"
                          :name="$tc('item', 1)"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            class="rounded-lg rounded-b-0 matrix-input"
                            :error-messages="errors[0]"
                            :placeholder="$t('item')"
                            v-model="matrix_item"
                          ></v-text-field>
                        </ValidationProvider>
                        <span class="separator"></span>
                        <ValidationProvider
                          vid="value"
                          :name="$tc('value', 1)"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            class="rounded-lg rounded-b-0 matrix-input"
                            :error-messages="errors[0]"
                            :placeholder="$tc('value', 1)"
                            v-model="matrix_value"
                          ></v-text-field>
                        </ValidationProvider>
                      </th>

                      <!-- Columnas -->
                      <th
                        v-for="(col, key) of columns"
                        :key="`column-${key}`"
                        :class="{ 'd-none': key === 0 }"
                      >
                        <div class="pt-3">
                          <ValidationProvider
                            vid="column"
                            rules="required"
                            :name="$tc('column', 1)"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              class="rounded-lg rounded-b-0 matrix-input"
                              dense
                              maxlength="20"
                              :error-messages="errors[0]"
                              :value="col.label"
                              @input="setColumn($event, key)"
                            ></v-text-field>
                          </ValidationProvider>
                        </div>
                      </th>

                      <!-- Botones -->
                      <th class="text-center">
                        <v-btn
                          class="mr-1"
                          color="error"
                          small
                          :disabled="current != 0"
                          @click="RemoveColumn"
                        >
                          <v-icon small>fa-minus</v-icon>
                        </v-btn>
                        <v-btn
                          color="success"
                          small
                          :disabled="current != 0"
                          @click="AddColumn"
                        >
                          <v-icon small>fa-plus</v-icon>
                        </v-btn>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, i) in rows[current]"
                      :key="`rangeRow-${current}-${i}`"
                    >
                      <!-- Filas -->
                      <td class="d-flex flex-row g-1 pt-3 border-0">
                        <ValidationProvider
                          vid="range_start"
                          :name="$t('matrix_table.initial_range')"
                          :rules="{
                            required: true,
                            min_value:
                              i == 0
                                ? 0
                                : one_row
                                ? Number(rows[current][i - 1][0].value[0]) + 1
                                : 0
                          }"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            class="rounded-lg rounded-b-0 matrix-input"
                            dense
                            :disabled="current != 0"
                            :error-messages="errors[0]"
                            :value="row[0].value[0]"
                            @input="setRow($event, i, 0, row[0].type, 0)"
                          ></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider
                          v-if="!one_row"
                          vid="range_end"
                          :name="$t('matrix_table.final_range')"
                          :rules="{
                            required: !one_row,
                            min_value: row[0].value[0]
                          }"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            class="rounded-lg rounded-b-0 matrix-input"
                            dense
                            :disabled="current != 0"
                            :error-messages="errors[0]"
                            :value="row[0].value[1]"
                            @input="setRow($event, i, 0, row[0].type, 1)"
                          ></v-text-field>
                        </ValidationProvider>
                      </td>
                      <td v-for="(r, j) in row" :key="j"></td>
                    </tr>
                    <tr v-show="current == 0" class="text-center">
                      <td class="d-flex g-1 justify-content-center py-3">
                        <v-btn small color="error" @click="RemoveRow">
                          <v-icon small>fa-minus</v-icon>
                        </v-btn>
                        <v-btn small color="success" @click="AddRow">
                          <v-icon small>fa-plus</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- Acciones rápidas flotante -->
          <v-speed-dial
            class="d-md-none"
            bottom
            direction="top"
            fixed
            right
            v-model="fab"
          >
            <template v-slot:activator>
              <v-btn v-model="fab" color="primary" dark fab>
                <v-icon v-if="fab">fa-angle-down</v-icon>
                <v-icon v-else>fa-sliders-h</v-icon>
              </v-btn>
            </template>

            <!-- Eliminar columna -->
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn fab dark color="error" v-on="on" @click="RemoveColumn">
                  <v-icon small>fa-arrow-left</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('matrix_table.remove_cols') }}</span>
            </v-tooltip>

            <!-- Agregar columna -->
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn fab dark color="success" v-on="on" @click="AddColumn">
                  <v-icon small>fa-arrow-right</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('matrix_table.add_cols') }}</span>
            </v-tooltip>

            <!-- Eliminar fila -->
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn fab dark color="error" v-on="on" @click="RemoveRow">
                  <v-icon small>fa-arrow-up</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('matrix_table.remove_rows') }}</span>
            </v-tooltip>

            <!-- Agregar fila -->
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn fab dark color="success" v-on="on" @click="AddRow">
                  <v-icon small>fa-arrow-down</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('matrix_table.add_rows') }}</span>
            </v-tooltip>
          </v-speed-dial>

          <!-- Modal: Agregar valores -->
          <v-dialog max-width="390" persistent v-model="dialogItemValue">
            <v-card>
              <v-toolbar dark color="primary">
                <v-toolbar-items>
                  <v-btn text dark @click="$router.back()">
                    <v-icon>fa-arrow-left</v-icon>
                  </v-btn>
                </v-toolbar-items>
                <v-spacer></v-spacer>
                <v-toolbar-title class="font-weight-black">
                  {{ $t('add_item_value') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn
                    text
                    dark
                    :loading="isLoading"
                    @click="AddItemValue"
                    v-show="btnSaveItemValue"
                  >
                    <template v-slot:loader>
                      <v-progress-circular
                        color="white"
                        indeterminate
                        width="3"
                      >
                        <v-icon
                          size="20"
                          v-text="'$vuetify.icons.loading'"
                        ></v-icon>
                      </v-progress-circular>
                    </template>
                    <v-icon>fa-save</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text>
                <v-row class="my-0">
                  <v-col class="pb-2" cols="12">
                    <v-text-field
                      label="Item"
                      hide-details
                      outlined
                      v-model="matrix_item"
                    />
                  </v-col>
                  <v-col class="pb-2" cols="12">
                    <v-text-field
                      hide-details
                      outlined
                      :label="$tc('value', 1)"
                      v-model="matrix_value"
                    />
                  </v-col>
                  <v-col cols="12">
                    <!-- Por rangos o una fila -->
                    <div class="container-v-switch">
                      <label :class="one_row ? '' : 'active'" for="one_row">
                        {{ $t('by_range') }}
                      </label>
                      <v-switch
                        id="one_row"
                        class="v-switch mt-0"
                        hide-details
                        :disabled="edit"
                        v-model="one_row"
                      ></v-switch>
                      <label :class="one_row ? 'active' : ''" for="one_row">
                        {{ $t('one_row') }}
                      </label>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>

          <!-- Modal: Tabs -->
          <v-dialog max-width="390" persistent v-model="dialogTab">
            <v-card>
              <v-toolbar dark color="primary">
                <v-toolbar-items>
                  <v-btn text dark @click="closeDialogTabs">
                    <v-icon>fa-times</v-icon>
                  </v-btn>
                </v-toolbar-items>
                <v-spacer></v-spacer>
                <v-toolbar-title class="font-weight-black">
                  {{ $t('create_value') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn text dark :loading="isLoading" @click="AddValue">
                    <template v-slot:loader>
                      <v-progress-circular
                        color="white"
                        indeterminate
                        width="3"
                      >
                        <v-icon
                          size="20"
                          v-text="'$vuetify.icons.loading'"
                        ></v-icon>
                      </v-progress-circular>
                    </template>
                    <v-icon>fa-save</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text class="py-4">
                <v-text-field
                  persistent-hint
                  outlined
                  :label="$tc('title')"
                  :hint="$tc('tabs_must_be_unique')"
                  v-model="tab"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-dialog>
        </form>
      </ValidationObserver>
    </v-card>
  </i-container>
</template>
<script>
import './matrix.sass'
export default {
  props: {
    container: {
      type: String,
      default: 'container'
    },
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: Object,
      default: null
    },
    productionId: {
      type: Number,
      default: 0
    },
    productionName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      key: 1,
      edit: false,
      counter: 0,
      current: 0,
      columns: [{ label: 'Item/Value', field: 'init' }],
      tabs: [],
      tab: '',
      one_row: false,
      rows: { 0: [] },
      matrix: {
        pk: '',
        name: '',
        matrix_type: '1D',
        rows_title: [],
        matrix_template_values: []
      },
      matrix_item: '',
      matrix_value: '',
      loading: false,
      isLoading: false,
      isLoading2: false,
      search: '',
      dialog: false,
      fab: false,
      dialogTab: false,
      dialogItemValue: false
    }
  },
  methods: {
    openDialogTabs() {
      this.tab = ''
      this.dialogTab = true
    },
    closeDialogTabs() {
      this.dialogTab = false
      this.tab = ''
    },
    AddItemValue() {
      if (this.one_row) {
        if (this.matrix_item != '') {
          this.dialogItemValue = false
        }
      } else {
        if (this.matrix_item != '' && this.matrix_value != '') {
          this.dialogItemValue = false
        }
      }
    },
    AddColumn() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.counter++
          this.columns.splice(this.columns.length, 0, {
            label: '',
            field: 'added_' + this.counter
          })
        }
      })
    },
    AddRow() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          let tabs = this.tabs.length == 0 ? [0] : this.tabs
          for (let key in tabs) {
            let row = []
            let item = {}
            let lastValue = this.one_row
              ? this.rows[key].length == 0
                ? ''
                : Number(
                    this.rows[key][this.rows[key].length - 1][0].value[0]
                  ) + 1
              : this.rows[key].length == 0
              ? ''
              : Number(this.rows[key][this.rows[key].length - 1][0].value[1]) +
                1
            item['init'] = ''
            item['value'] = [lastValue, '']
            item['type'] = 'init'
            row.push(item)
            this.rows[key].push(row)
          }
        }
      })
    },
    RemoveColumn() {
      if (this.columns.length > 1) {
        this.columns.splice(this.columns.length - 1, 1)
        let tabs = this.tabs.length == 0 ? [0] : this.tabs
        for (let key in tabs) {
          for (let row of this.rows[key]) {
            row.pop()
          }
        }
      }
    },
    RemoveRow() {
      let tabs = this.tabs.length == 0 ? [0] : this.tabs
      for (let key in tabs) {
        this.rows[key].pop()
      }
    },
    armMatrix() {
      this.matrix.matrix_template_values = []
      for (let current in this.rows) {
        if (this.tabs[current] !== undefined && this.tabs.length > 0) {
          this.matrix.matrix_type = '2D'
          this.matrix.rows_title.push(this.tabs[current].value)
        }
        var matrix_value = {
          pk: 0,
          row_name: this.tabs[current] ? this.tabs[current].value : '',
          rows_title: [],
          col_title: [],
          item_value: { item: this.matrix_item, value: this.matrix_value }
        }
        if (!this.edit) delete matrix_value.pk
        // Iteration for asing columns values
        for (let col of this.columns) {
          if (col.field != 'init') {
            matrix_value.col_title.push(col.label)
          }
        }
        // Iteration for values
        for (var i = 0; i < this.rows[current].length; i++) {
          // Append rows titles
          if (!this.rows[current][i][0].length) {
            let new_title =
              this.rows[current][i][0].value.length == 1
                ? this.rows[current][i][0].value[0] + ' - '
                : this.rows[current][i][0].value[0] +
                  ' - ' +
                  this.rows[current][i][0].value[1]
            matrix_value.rows_title.push(new_title)
          }
        }
        this.matrix.matrix_template_values.push(matrix_value)
      }
    },
    setColumn(value, key) {
      this.columns[key].label = value
    },
    setRow(value, i, j, type, pos) {
      if (type) {
        for (let key in this.rows) {
          if (type == 'cost') {
            this.rows[key][i][j][0].value = value
          } else if (type == 'price') {
            this.rows[key][i][j][1].value = value
          } else {
            this.rows[key][i][j].value[pos] = value
          }
        }
      }
    },
    validateMatrix() {
      // Check tabs title
      for (let tabs of this.tabs) {
        let count = 0
        for (let i in this.tabs) {
          if (this.tabs[i] == tabs) {
            count++
          }
        }
        if (count > 1) {
          this.$toast.error(`${this.$t('cols_tab_be_unique')}`)
          return false
        }
      }
      // Check rows title
      for (let title of this.matrix.rows_title) {
        let count = 0
        for (let i in this.matrix.rows_title) {
          if (this.matrix.rows_title[i] == title) {
            count++
          }
        }
        if (count > 1) {
          this.$toast.error(`${this.$t('rows_must_be_unique')}`)
          return false
        }
      }
      // Check colum values
      for (let values of this.matrix.matrix_template_values) {
        for (let title of values.col_title) {
          let count = 0
          for (let i in values.col_title) {
            if (values.col_title[i] == title) {
              count++
            }
          }
          if (count > 1) {
            this.$toast.error(`${this.$t('rows_must_be_unique')}`)
            return false
          }
        }
      }
      return true
    },
    AddValue() {
      if (this.tab != '') {
        this.tabs.push({ value: this.tab })
        this.rows[this.tabs.length - 1] = JSON.parse(
          JSON.stringify(this.rows[0])
        )
        if (this.rows[this.tabs - 1] === undefined) {
          this.closeDialogTabs()
        }
        if (this.tabs.length - 1 != 0) {
          for (var i = 0; i < this.rows[this.tabs - 1].length; i++) {
            if (this.columns !== undefined && this.tabs.length !== undefined) {
              for (var j = 0; j < this.columns.length; j++) {
                for (
                  var k = 0;
                  k < this.rows[this.tabs.length - 1][i][j].length;
                  k++
                ) {
                  if (
                    this.rows[this.tabs.length - 1][i][j][k].type == 'cost' ||
                    this.rows[this.tabs.length - 1][i][j][k].type == 'price'
                  ) {
                    this.rows[this.tabs.length - 1][i][j][k].pk = 0
                  }
                }
              }
            }
          }
        }
        this.tab = ''
        this.closeDialogTabs()
      } else {
        this.$toast.error(`${this.$t('value')} ${this.$t('required')}`)
      }
    },
    RemoveValue() {
      this.tabs.pop()
    },
    tabValue(text) {
      for (let element in this.tabs) {
        if (this.tabs[element].value == text) {
          this.current = element
          break
        }
      }
    },
    async setValues() {
      let edit = ''
      let validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      if (validation === 'create' && this.toEdit === null) {
        edit = false
        if (this.$store.getters['utils/getIsloading']) {
          this.$store.dispatch('utils/actionLoading')
        }
        this.matrix = {
          pk: '',
          name: '',
          matrix_type: '1D',
          rows_title: [],
          matrix_template_values: []
        }
        this.dialogItemValue = true
      } else {
        edit = true
        try {
          this.loading = true
          let response = await this.$api.matrix.template.show({
            pk: this.toEdit !== null ? this.toEdit.pk : validation
          })
          let matrix = response.data
          matrix.matrix_template_values =
            matrix.matrix_template_values.reverse()
          this.one_row = false
          this.edit = edit
          //Set defaults
          this.dialog = true
          this.columns = [{ label: 'Item/Value', field: 'init' }]
          this.rows = { 0: [] }
          this.tabs = []
          this.current = 0
          let qty = 1
          // Set values
          if (this.container === 'container') this.matrix.name = matrix.name
          this.matrix.matrix_type = matrix.matrix_type
          if (this.edit) {
            this.matrix.pk = matrix.pk
            this.matrix_item = matrix.matrix_template_values[0].item_value.item
            this.matrix_value =
              matrix.matrix_template_values[0].item_value.value
          }
          if (matrix.matrix_type == '2D') {
            for (let tab of matrix.rows_title) {
              this.tabs.push({ value: tab })
            }
            qty = matrix.rows_title.length
          }
          // Set values on column
          for (let label of matrix.matrix_template_values[0].col_title) {
            this.counter++
            this.columns.splice(this.columns.length, 0, {
              label: label,
              field: 'added_' + this.counter
            })
          }
          for (var current = 0; current < qty; current++) {
            //Set values on rows
            if (matrix.matrix_template_values[current] != undefined) {
              for (
                var i = 0;
                i < matrix.matrix_template_values[current].rows_title.length;
                i++
              ) {
                let row = []
                let init = {}
                for (
                  var j = 0;
                  j < matrix.matrix_template_values[current].col_title.length;
                  j++
                ) {
                  let item = []
                  // Set init
                  let title =
                    matrix.matrix_template_values[current].rows_title[i].split(
                      ' - '
                    )
                  if (title.length == 1) {
                    this.one_row = true
                    title = [title[0].replace(' -', '')]
                  }
                  init['init'] = ''
                  init['value'] = title
                  init['type'] = 'init'
                  // Set Cost
                  let cost = {}
                  let price = {}
                  // If one row
                  if (!this.one_row && this.columns[i] != undefined) {
                    cost[this.columns[i].field] = ''
                    price[this.columns[i].field] = ''
                  }
                  // edit
                  if (this.edit) {
                    cost['pk'] = matrix.matrix_template_values[current].pk
                    price['pk'] = matrix.matrix_template_values[current].pk
                  }
                  cost['value'] = null
                  cost['label'] = this.$tc('cost', 1)
                  cost['type'] = 'cost'
                  // Set Price
                  price['value'] = null
                  price['label'] = this.$tc('price', 1)
                  price['type'] = 'price'
                  item.push(cost, price)
                  row.push(item)
                }
                // Append row zero titles
                row.splice(0, 0, init)

                this.rows[this.current].push(row)
              }
            }
            if (
              matrix.matrix_type == '2D' &&
              this.tabs.length - 1 > this.current
            ) {
              this.current++
              this.rows[this.current] = []
            }
          }
          this.current = 0
        } finally {
          this.loading = false
          if (this.matrix_item == '' && this.matrix_value == '')
            this.dialogItemValue = true
        }
      }
    },
    /**
     * viewMatrix
     * Método para hacer una copia de la edición de la
     * plantilla de la matriz principal de la plantilla
     * de proceso de producción y a partir de los rangos
     * de ésta nueva matriz se puedan crear las matrices
     * de detalle
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async copyMainMatrix() {
      const response = await this.$api.matrix.template.show({
        pk: this.matrix.pk,
        opt: {
          params: {
            clone: 'details',
            productionId: this.productionId,
            name: this.matrix.name
          }
        }
      })
      let matrix = response.data
      await this.$api.matrix.template.edit({
        pk: matrix.pk,
        form: this.matrix
      })
      this.$emit('update:toEdit', matrix)
      this.$toast.success(
        `${this.$tc('matrix_template', 1)} ${this.$tc('created', 2)}`
      )
    },
    async submit() {
      this.armMatrix()
      if (this.container === 'modal') {
        if (this.toEdit != null) this.copyMainMatrix()
        this.$emit('input', false)
      } else {
        if (this.matrix.pk === '' && this.validateMatrix()) {
          await this.$api.matrix.template.create({
            form: this.matrix
          })
        }
        if (this.matrix.pk !== '') {
          await this.$api.matrix.template.edit({
            pk: this.matrix.pk,
            form: this.matrix
          })
        }
        this.$router.push({ name: 'matrix' })
        this.$toast.success(
          `${this.$tc('matrix', 1)} ${this.$tc(
            this.matrix.pk !== '' ? 'edited' : 'created',
            2
          )}`
        )
      }
    }
  },
  computed: {
    btnSaveItemValue() {
      if (this.one_row) {
        if (this.matrix_item != '') return true
      } else if (this.matrix_item != '' && this.matrix_value != '') {
        return true
      }
      return false
    },
    /**
     * opts
     * Computado para modal de plantilla de matriz
     * Se muestra cuando container es igual a modal
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    opts() {
      switch (this.container) {
        case 'modal':
          return {
            title: this.$t('editing', { value: this.$t('main_matrix') }),
            value: this.value,
            load: this.isLoading2,
            maxWidth: '80%',
            dontSave: true
          }
        default:
          return { fluid: true }
      }
    }
  },
  mounted() {
    this.setValues()
  }
}
</script>
